#root {
  /* var */
  --emoji-size: 55px;
  --emoji-size-big: 70px;
  --emoji-size-small: 40px;
  --emoji-size-smaller: 30px;
  --emoji-size-smallest: 20px;
  --card-height: 200px;
  --card-width: 450px;
  --card-radius: 25px;
  --card-bg: #fff;
  --card-color: #000;
  --card-shade: #f5f5f5c7;
  --card-border-color: #e6e6e6;
  --card-text-color: #000;
  --card-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  color: var(--card-color);
}

.emoji {
  font-size: var(--emoji-size);
}

.emoji-column {
  margin: 0 5px;
  float: left;
  width: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emoji-wall {
  box-shadow: var(--card-shadow);
}

.emoji-wall .emoji-column:nth-child(2n) {
  margin-top: -115px;
}

.emoji-container {
  filter: blur(2px);
}

.emoji-column:nth-child(2n) .emoji:nth-child(2n) {
  font-size: var(--emoji-size-big);
}

.emoji-column:nth-child(2n+1) .emoji:nth-child(2n+1) {
  font-size: var(--emoji-size-big);
}