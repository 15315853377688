.EmojiPickerReact {
    width: 100% !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.new-post-header-emoji-picker {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.new-post-header {
    width: 100%;
    border-bottom: 1px solid var(--card-color);
    position: sticky;
}

.new-post-header-avatar {
    display: flex;
}

.new-post-header-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.new-post-header-input {
    margin-left: 50px;
    margin-top: -15px;
}

.new-post-header-input input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
}

.new-post-header-avatar-name-full {
    position: absolute;
    font-weight: bold;
    margin-left: 10px;
}

.new-post-header-attachment {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-post-header-attachment-button {
    margin: 5px 45px;
}

.new-post-header-attachment-button svg {
    margin: 0px;
}

.new-post-header-input-button {
    border: none;
    background-color: transparent;
    color: rgba(0, 0, 235, 0.75);
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px 10px;
    float: right;
    margin: 5px 10px;
}

.new-post-header-attachment-button {
    border: none;
    background-color: transparent;
    color: var(--card-text-color);
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px 10px;
}