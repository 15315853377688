.sign-in-header {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  margin-bottom: 0px;
  max-width: 600px;
}

.sign-in-header h1 {
  margin: 0 10px;
  font-size: 40px;
}

.sign-in-subheader {
  margin: 30px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0 10px;
}

.sign-in-body-button {
  margin: 30px;
  display: flex;
}

.sign-in-body-button svg {
    transform: translateY(3px);
    margin-right: 10px;
}

.sign-in-body-button button {
  max-width: 300px;
  border: 2px solid var(--card-border-color);
  padding: 10px 16px;
  background-color: transparent;
  cursor: pointer;
  color: var(--card-text-color);
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 5px auto;
}

.create-user-account {
  margin: 30px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0 10px;
}

.create-user-account-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  padding: 0 10px;
}

.create-user-account-form input {
  border: 2px solid var(--card-border-color);
  padding: 10px 16px;
  background-color: transparent;
  cursor: pointer;
  color: var(--card-text-color);
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 5px auto;
}

.create-user-account-form label {
  font-size: 20px;
}

.create-user-account-form button {
  max-width: 300px;
  border: 2px solid var(--card-border-color);
  padding: 10px 16px;
  background-color: var(--card-bg);
  cursor: pointer;
  color: var(--card-text-color);
  font-size: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 5px auto;
}

.create-user-account-subheader-example {
  margin: 30px auto;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

.create-user-account-subheader-example-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.create-user-account-subheader-example h2 {
  margin: 0 10px;
  font-size: 30px;
}