.post {
    max-width: 600px;
    max-height: 200px;
    padding: 15px;
    border-top: 0;
    background-color: var(--card-bg);
    color: var(--card-text-color);
    z-index: 0;
}

.post-divider {
    max-width: 600px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--card-border-color);
    transform: translateY(10px);
}

.post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;   
}

.post-vertical-line {
    width: 1px;
    height: 100%;
    background-color: var(--card-border-color);
}

.post-avatar {
    width: 100%;
    height: 40px;
}

.post-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.post-avatar .post-avatar-name {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 1rem;
    display: inline-flex;
    position: absolute;
}

.post-avatar-name .post-avatar-name-full {
    cursor: pointer;
    font-weight: bold;
}

.post-avatar-name-full:hover {
    text-decoration: underline;
}

.post-avatar-name .post-avatar-name-tag {
    margin-left: 5px;
    opacity: 0.5;
}

.post-footer {
    margin-left: 35px;
}

.post-footer-button button {
    border: none;
    margin-right: 10px;
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
    color: var(--card-text-color);
    font-size: 15px;
}

.post-content-text {
    margin-left: 50px;
    margin-bottom: 15px;
    font-size: 20px
}

.post-footer-button-count {
    margin-left: 5px;
    display: inline-flex;
    white-space: nowrap;
    opacity: 0.5;
    float: right;
    font-size: 15px;
}

.post-footer-button-count span {
    margin: 0 5px;
}